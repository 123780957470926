// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/vercel/path0/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-treatment-template-js": () => import("/vercel/path0/src/templates/treatment-template.js" /* webpackChunkName: "component---src-templates-treatment-template-js" */),
  "component---src-templates-news-template-js": () => import("/vercel/path0/src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/vercel/path0/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/vercel/path0/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-js": () => import("/vercel/path0/src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-js": () => import("/vercel/path0/src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-privacy-js": () => import("/vercel/path0/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-and-condtions-js": () => import("/vercel/path0/src/pages/terms-and-condtions.js" /* webpackChunkName: "component---src-pages-terms-and-condtions-js" */),
  "component---src-pages-treatments-js": () => import("/vercel/path0/src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */)
}

