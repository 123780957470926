module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["icomoon"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149354357-1"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Step 2 Wellbeing","short_name":"Step 2 Wellbeing","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/favicon.png"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
